<template>
    <div
        class="writer-tab-reviews__item-body-other-details"
        :class="{
            'writer-tab-reviews__item-body-other-details--success': counter >= 4,
            'writer-tab-reviews__item-body-other-details--unsuccessful': counter < 4
        }"
    >
        <div>
            <span class="writer-tab-reviews__item-body-other-details-title">
                {{ title }}
            </span>
            <p
                v-if="details"
                class="writer-tab-reviews__item-body-other-details-content"
            >
                {{ details }}
            </p>
        </div>
        <div
            class="writer-tab-reviews__item-body-other-details-count"
            :class="{
                'writer-tab-reviews__item-body-other-details-count--success': counter >= 4,
                'writer-tab-reviews__item-body-other-details-count--unsuccessful': counter < 4
            }"
        >
            {{ counter.slice(0, -1) }}
            <span class="writer-tab-reviews__item-body-other-details-count-all">/ 5.0</span>
        </div>
    </div>
</template>

<script>

export default {
    name: 'WriterReviewAdditionalBlock',
    props: {
        title: {
            type: String
        },
        details: {
            type: String
        },
        counter: {
            type: String
        }
    }
}
</script>
