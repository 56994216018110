<template>
    <div class="progress-bar">
        <div class="progress-bar__background" />
        <transition
            appear
            @before-appear="beforeEnter"
            @after-appear="enter"
        >
            <div class="progress-bar__tracker" />
        </transition>
    </div>
</template>

<script>
export default {
    props: {
        count: {
            type: Number,
            require: true
        },
        maxCount: {
            type: Number,
            require: true
        }
    },
    computed: {
        getProgress() {
            return (this.count / this.maxCount) * 100
        }
    },
    methods: {
        beforeEnter(el) {
            el.style.width = 0
        },
        enter(el) {
            el.style.width = `${this.getProgress}%`
            el.style.transition = 'width 1s linear'
        }
    }
}
</script>

<style lang="scss">
.progress-bar {
    width: 100%;
    &__background {
        background: #D7E1F2;
        border-radius: 4px;
        width: 100%;
        height: 5px;
    }

    &__tracker {
        max-width: 100%;
        background: linear-gradient(94.79deg, #212938 0.59%, #374153 98.53%);
        border-radius: 4px;
        height: 5px;
        width: 0;
        transition: width 0.1s linear;
        margin-top: -5px;
    }
}
</style>
