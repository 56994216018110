<template>
    <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M7.71468 1.87811C7.80449 1.60172 8.19551 1.60172 8.28532 1.87812L9.72876 6.32057C9.76892 6.44418 9.88411 6.52786 10.0141 6.52786H14.6851C14.9758 6.52786 15.0966 6.89975 14.8615 7.07057L11.0825 9.81616C10.9774 9.89255 10.9334 10.028 10.9735 10.1516L12.417 14.594C12.5068 14.8704 12.1904 15.1003 11.9553 14.9294L8.17634 12.1838C8.07119 12.1074 7.92881 12.1075 7.82366 12.1838L4.04469 14.9294C3.80957 15.1003 3.49323 14.8704 3.58304 14.594L5.02648 10.1516C5.06664 10.028 5.02264 9.89255 4.91749 9.81616L1.13852 7.07057C0.903402 6.89975 1.02424 6.52786 1.31485 6.52786H5.98593C6.11589 6.52786 6.23108 6.44418 6.27124 6.32057L7.71468 1.87811Z"
            stroke="#FF690C"
            stroke-width="1.5"
        />
    </svg>
</template>
<script>
export default {
    name: 'Star'
}
</script>
<style scoped lang='scss'>
svg {
    width: 16px;
    height: 16px;
}
svg path {
    stroke: $main-color;
}
</style>
