<template>
    <div class="writer-progress-bar">
        <p>{{ title }}</p>
        <div class="writer-progress-bar__stats">
            <progress-bar
                :count="progress"
                :max-count="5"
            />
            <p
                class="writer-progress-bar__stats-total"
            >
                {{ progress }}
            </p>
        </div>
    </div>
</template>

<script>
import ProgressBar from '@/components/common/ProgressBar.vue';

export default {
    components: {
        ProgressBar
    },
    props: {
        title: {
            type: String
        },
        progress: {
            type: Number
        }
    }
}
</script>

<style lang="scss">

.writer-progress-bar {
    display: flex;
    justify-content: space-between;
    width: 50%;
    padding: 10px 20px;
    font-weight: 400;
    font-size: 14px;
    color: #111720;
    @media (max-width: 576px) {
        width: 100%;
    }
    &__stats{
        width: 50%;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    &__stats-total{
        margin-left: 15px;
    }
}

</style>
