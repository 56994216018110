<template>
    <div>
        <double-form-element>
            <template>
                <form-select
                    v-model="searchObjectSort.sort_by"
                    :field.sync="searchObjectSort.sort_by"
                    :value-select="searchObjectSort.sort_by"
                    :items="sortByFields"
                    name="Sort by"
                />
                <form-select
                    v-model="searchObjectSort.sort_order"
                    :field.sync="searchObjectSort.sort_order"
                    :value-select="searchObjectSort.sort_order"
                    :items="sortOrderFields"
                    name="Sort order"
                />
            </template>
        </double-form-element>
    </div>
</template>

<script>
export default {
    name: 'OrderExpressFilter',
    props: {
        searchObject: {
            type: Object
        },
        sortByFields: {
            type: Array,
            required: true
        },
        sortOrderFields: {
            type: Array,
            required: true
        }
    },
    data() {
        return {
            searchObjectSort: null
        }
    },
    computed: {
        updateSort() {
            return {
                ...this.searchObject,
                ...this.searchObjectSort
            }
        }
    },
    watch: {
        searchObjectSort: {
            handler() {
                this.$emit('updateSorting', { ...this.updateSort })
            },
            deep: true
        },
        searchObject: {
            handler() {
                const query = {
                    sort_by: this.searchObject.sort_by,
                    sort_order: this.searchObject.sort_order
                }
                if (JSON.stringify(this.searchObjectSort) !== JSON.stringify(query)) {
                    this.searchObjectSort = { ...query }
                }
            },
            deep: true
        }
    },
    created() {
        this.searchObjectSort = {
            sort_by: this.searchObject.sort_by,
            sort_order: this.searchObject.sort_order
        }
    }
}
</script>
